import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { notification } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import Timeline, { DateHeader, TimelineHeaders, TodayMarker, Unit } from 'react-calendar-timeline';
import { CaretDownFilled, CaretRightFilled } from '@ant-design/icons';
import { Tooltip } from 'react-tooltip';
import { useMutation } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';

import TimelineItem from './timeline-item';
import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { queryClient } from 'index';
import { actionCheckIn, actionCheckOut } from 'services/api/module/booking.api';
import QUERY_KEYS from 'services/api/queryKeys';
import Emitter, { EVENT_KEY } from 'utils/eventEmitter';
import {
  FILTER_STATUS_ROOM_MAP_BACKGROUND,
  FILTER_STATUS_ROOM_MAP_VALUE,
  GROUP_DISPLAY_TYPE,
  TIMELINE_UNIT
} from 'constants/common';

import { BookingLine, NewRoomLock } from 'services/api/type/booking.type';
import { RoomDetailStatus } from 'services/api/type/room.type';
import { ReactComponent as IconDirty } from 'assets/images/dirty.svg';
import { ACTION_BOOKING } from 'components/booking-list/action-booking';

interface Props {
  bookings: BookingLine[];
  roomsNotAssign: BookingLine[];
  sortedRooms: RoomDetailStatus[] | undefined;
  locks: NewRoomLock[];
  timelineUnit: Unit;
  groupTypeDisplay: GROUP_DISPLAY_TYPE;
  isLoadingTimeline?: boolean;
  isFetchedAfterMount?: boolean;
  isFetching?: boolean;
}

const MIN_ZOOM_VALUE: {
  [key: string]: number;
} = {
  [TIMELINE_UNIT.DAY]: 60 * 60 * 1000,
  [TIMELINE_UNIT.WEEK]: 60 * 60 * 1000 * 24,
  [TIMELINE_UNIT.MONTH]: 60 * 60 * 1000 * 24
};

const MAX_ZOOM_VALUE: {
  [key: string]: number;
} = {
  [TIMELINE_UNIT.DAY]: 60 * 60 * 1000 * 24,
  [TIMELINE_UNIT.WEEK]: 60 * 60 * 1000 * 24 * 7,
  [TIMELINE_UNIT.MONTH]: 60 * 60 * 1000 * 24 * 30
};

const HEADER_TIME: {
  [key: string]: string;
} = {
  [TIMELINE_UNIT.DAY]: TIMELINE_UNIT.HOUR,
  [TIMELINE_UNIT.WEEK]: TIMELINE_UNIT.DAY,
  [TIMELINE_UNIT.MONTH]: TIMELINE_UNIT.DAY
};

const TIME_MOBILE = 6;

const startTime = dayjs().startOf('day').valueOf();
const endTime = dayjs().endOf('day').valueOf();

const TimelineBooking = forwardRef(function TimelineBooking(
  {
    bookings,
    roomsNotAssign,
    sortedRooms,
    locks,
    timelineUnit,
    groupTypeDisplay,
    isFetching
  }: Props,
  ref
) {
  const {
    setIsOpenCancelRoom,
    setInfoConfirmModal,
    setIsOpenAddService,
    setIsOpenChangeRoom,
    setIsOpenChangeDate,
    setIsOpenCancelAssignRoom,
    setIsOpenFixRoom,
    setOpenBookingDetail
  } = useModal();

  const isSmallDesktopMobile = useMediaQuery({
    query: '(max-width: 1024px)'
  });
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  const [visibleTime, setVisibleTime] = useState({
    visibleTimeStart: startTime,
    visibleTimeEnd: isIpadMobile
      ? dayjs().startOf('day').add(TIME_MOBILE, 'hour').valueOf()
      : dayjs(startTime).add(6, 'day').endOf('day').valueOf()
  });
  const [openGroups, setOpenGroups] = useState<{ [key: string]: boolean }>({});
  const [mousePositionX, setMousePositionX] = useState(0);
  const [mousePositionY, setMousePositionY] = useState(0);

  const { setBookingLineId, setRoom } = useBookingStore();

  const { mutateAsync: mutateCheckIn } = useMutation({
    mutationFn: (bookingLineId: number) => actionCheckIn(bookingLineId)
  });

  const { mutateAsync: mutateCheckOut } = useMutation({
    mutationFn: (bookingLineId: number) => actionCheckOut(bookingLineId)
  });

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const groupedData = _.groupBy(bookings, item => item.room_type_name);
    const newOpenGroups: any = {};
    Object.keys(groupedData).forEach(type => (newOpenGroups[type] = true));
    setOpenGroups(newOpenGroups);
  }, [bookings]);

  useImperativeHandle(ref, () => {
    return {
      moveToday() {
        setVisibleTime({
          visibleTimeStart: startTime,
          visibleTimeEnd: isIpadMobile
            ? dayjs(endTime).startOf('day').add(TIME_MOBILE, 'hour').valueOf()
            : endTime
        });
      },
      changeVisibleTime(fromDate: Dayjs, toDate: Dayjs, changeVisibleTime: TIMELINE_UNIT) {
        if (isIpadMobile && changeVisibleTime === TIMELINE_UNIT.DAY) {
          setVisibleTime({
            visibleTimeStart: dayjs(fromDate).startOf('day').valueOf(),
            visibleTimeEnd: dayjs(toDate).startOf('day').add(TIME_MOBILE, 'hour').valueOf()
          });
        } else {
          setVisibleTime({
            visibleTimeStart: dayjs(fromDate).startOf('day').valueOf(),
            visibleTimeEnd: dayjs(toDate).endOf('day').valueOf()
          });
        }
      }
    };
  }, [isIpadMobile]);

  const handleCheckIn = async (bookingLineId: number) => {
    try {
      await mutateCheckIn(bookingLineId);
      notification.success({
        message: 'Nhận phòng thành công'
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_BOOKING_TIMELINE]
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Lỗi xảy ra'
      });
    }
  };

  const handleCheckOut = async (bookingLineId: number) => {
    try {
      await mutateCheckOut(bookingLineId);
      notification.success({
        message: 'Trả phòng thành công'
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_BOOKING_TIMELINE]
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Lỗi xảy ra'
      });
    }
  };

  const handleMouseMove = (event: any) => {
    // Include scroll offset to ensure correct position
    // console.log(`Offset X/Y: ${event.offsetX}, ${event.offsetY}`);
    // console.log(`Viewport  X/Y: ${event.clientX}, ${event.clientY}`);
    // console.log(`Page  X/Y: ${event.pageX}, ${event.pageY}`);
    // console.log(`Screen  X/Y: ${event.screenX}, ${event.screenY}`);
    // console.log('---------------------------');
    setMousePositionX(event.clientX);
    setMousePositionY(event.clientY);
    Emitter.emit(EVENT_KEY.CHANGE_MOUSE_POSITION, {
      x: event.clientX,
      y: event.clientY + window.scrollY
    });
  };

  const handleTimeChange = (visibleTimeStart: number, visibleTimeEnd: number) => {
    setVisibleTime({
      visibleTimeStart: visibleTimeStart,
      visibleTimeEnd: visibleTimeEnd
    });
  };

  const handleClickMenu = async (menu: any, item: any) => {
    const bookingLineId = +_.get(item, 'booking.booking_line_id');

    switch (menu.key) {
      case ACTION_BOOKING.DETAIL:
        setBookingLineId(bookingLineId);
        setOpenBookingDetail(true, bookingLineId);
        break;
      case ACTION_BOOKING.ADD_SERVICE:
        setBookingLineId(bookingLineId);
        setIsOpenAddService(true);
        break;
      case ACTION_BOOKING.CANCEL_BOOKING:
        setBookingLineId(bookingLineId);
        setIsOpenCancelRoom(true);
        break;
      case ACTION_BOOKING.CHANGE_ROOM: {
        setBookingLineId(bookingLineId);
        setIsOpenChangeRoom(true);
        break;
      }
      case ACTION_BOOKING.CHANGE_DATE: {
        setBookingLineId(bookingLineId);
        setIsOpenChangeDate(true);
        break;
      }
      case ACTION_BOOKING.GET_ROOM: {
        setInfoConfirmModal(true, {
          title: 'Xác nhận nhận phòng',
          onOk: () => {
            setInfoConfirmModal(false);
            handleCheckIn(bookingLineId);
          }
        });
        break;
      }
      case ACTION_BOOKING.CHECK_OUT: {
        setInfoConfirmModal(true, {
          title: 'Xác nhận trả phòng',
          onOk: () => {
            setInfoConfirmModal(false);
            handleCheckOut(bookingLineId);
          }
        });
        break;
      }
      case ACTION_BOOKING.CANCEL_ASSIGN_ROOM: {
        setBookingLineId(Number(bookingLineId));
        setIsOpenCancelAssignRoom(true);
        break;
      }
      case ACTION_BOOKING.FIX_ROOM: {
        const roomLock = {
          lock_id: item.lock_id,
          reason: item.title,
          start_date: item.start_time,
          end_date: item.end_time
        };
        setRoom(item.id, item.room_no, roomLock);
        setIsOpenFixRoom(true);
        break;
      }
      default:
        break;
    }
  };

  const groups = useMemo(() => {
    if (groupTypeDisplay === GROUP_DISPLAY_TYPE.ROOM_TYPE) {
      const groupedData = _.groupBy(sortedRooms, item => item.room_type_name);
      const flattenData: any = [];

      const handleToggleGroup = (type: string) => {
        setOpenGroups({ ...openGroups, [type]: !openGroups[type] });
      };

      Object.keys(groupedData).forEach(type => {
        flattenData.push({
          id: type,
          title: (
            <span
              className="timeline-row timeline-row--parent"
              onClick={() => handleToggleGroup(type)}
              style={{ cursor: 'pointer' }}
            >
              {openGroups[type] ? <CaretDownFilled /> : <CaretRightFilled />} {type}
            </span>
          )
        });

        if (openGroups[type]) {
          groupedData[type].forEach(room => {
            flattenData.push({
              id: room.room_id,
              title: (
                <span className="timeline-row">
                  {room.room_name}
                  {/* {!room.is_clean && <IconDirty />} */}
                </span>
              )
            });
          });
        }
      });

      return flattenData;
    }

    return sortedRooms?.map(room => {
      return {
        id: room.room_id,
        title: (
          <span className="timeline-row">
            {room?.room_name}
            {!room.is_clean && <IconDirty />}
          </span>
        )
      };
    });
  }, [groupTypeDisplay, sortedRooms, openGroups]);

  const items = useMemo(() => {
    const lockRooms: any = locks.map(room => ({
      id: room.room_id,
      group: room.room_id,
      title: room.reason,
      canMove: false,
      canResize: false,
      canChangeGroup: false,
      start_time: dayjs.utc(room.start_date).local().toDate(),
      end_time: dayjs.utc(room.end_date).local().toDate(),
      lock_id: room.lock_id,
      room_no: _.get(room, 'attributes.room_no'),
      background: FILTER_STATUS_ROOM_MAP_BACKGROUND[FILTER_STATUS_ROOM_MAP_VALUE.LOCKED]
    }));

    return (
      bookings
        ?.filter(booking => booking.room_id)
        ?.map(booking => ({
          id: booking.booking_line_id,
          group: booking.room_id,
          title: booking.hotel_travel_agency_name
            ? `${booking.hotel_travel_agency_name} - ${booking.partner_name}`
            : booking.partner_name,
          start_time: dayjs.utc(booking.check_in).local().toDate(),
          end_time: dayjs.utc(booking.check_out).local().toDate(),
          canMove: false,
          canResize: false,
          canChangeGroup: false,
          booking,
          background: FILTER_STATUS_ROOM_MAP_BACKGROUND[booking.status]
        }))
        .concat(lockRooms) || []
    );
  }, [bookings, locks]);

  const countRoomsNotAssigned = (startDate: any) => {
    const count = roomsNotAssign.filter(room => {
      const roomStart = dayjs(room.check_in);
      const roomEnd = dayjs(room.check_out);

      return (
        roomStart.isSame(startDate, 'day') ||
        roomEnd.isSame(startDate, 'day') ||
        (roomStart.isBefore(startDate, 'day') && roomEnd.isAfter(startDate, 'day'))
      );
    }).length;

    return count === 0 ? null : count;
  };

  return (
    <div className="pms-timeline">
      {!_.isEmpty(sortedRooms) && isFetching && (
        <Timeline
          groups={groups}
          items={items}
          canResize={false}
          canMove={false}
          visibleTimeStart={visibleTime.visibleTimeStart}
          visibleTimeEnd={visibleTime.visibleTimeEnd}
          maxZoom={MAX_ZOOM_VALUE[timelineUnit]}
          minZoom={MIN_ZOOM_VALUE[timelineUnit]}
          onTimeChange={handleTimeChange}
          itemHeightRatio={0.7}
          lineHeight={40}
          itemRenderer={({ item, itemContext, getItemProps }: any) => {
            // const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
            const itemProps = getItemProps(item.itemProps);
            _.set(itemProps, 'style.background', item.background);
            _.set(itemProps, 'style.borderColor', item.background);

            return (
              <TimelineItem
                itemContext={itemContext}
                itemProps={itemProps}
                item={item}
                mousePositionX={mousePositionX}
                mousePositionY={mousePositionY}
                onClickMenu={info => handleClickMenu(info, item)}
              />
            );
          }}
        >
          <TimelineHeaders>
            <TodayMarker interval={2000} date={new Date()}>
              {({ styles, date }) => (
                // date is value of current date. Use this to render special styles for the marker
                // or any other custom logic based on date:
                // e.g. styles = {...styles, backgroundColor: isDateInAfternoon(date) ? 'red' : 'limegreen'}
                <div style={styles} className="today-marker">
                  <div className="flag-time">{dayjs(date).format('HH:mm')}</div>
                </div>
              )}
            </TodayMarker>
            {timelineUnit === TIMELINE_UNIT.DAY && <DateHeader unit="day" />}
            <DateHeader
              className={timelineUnit === TIMELINE_UNIT.MONTH ? 'rct-dateHeader--month' : ''}
              labelFormat={([startTime]) => {
                const roomsNotAssignedCount = countRoomsNotAssigned(startTime);

                if (timelineUnit === TIMELINE_UNIT.DAY) {
                  return isSmallDesktopMobile ? (
                    <p>
                      {`${startTime.format('HH mm')}`}{' '}
                      {roomsNotAssignedCount !== null && (
                        <span className="circle">{roomsNotAssignedCount}</span>
                      )}
                    </p>
                  ) : (
                    <p>
                      {`${startTime.format('HH mm')}`}{' '}
                      {roomsNotAssignedCount !== null && (
                        <span className="circle">{roomsNotAssignedCount}</span>
                      )}
                    </p>
                  );
                }

                if (timelineUnit === TIMELINE_UNIT.WEEK) {
                  return isSmallDesktopMobile ? (
                    <p>
                      {`${startTime.format('dd D')}`}{' '}
                      {roomsNotAssignedCount !== null && (
                        <span className="circle">{roomsNotAssignedCount}</span>
                      )}
                    </p>
                  ) : (
                    <p>
                      {`${startTime.format('dd D')}`}{' '}
                      {roomsNotAssignedCount !== null && (
                        <span className="circle">{roomsNotAssignedCount}</span>
                      )}
                    </p>
                  );
                }

                return isSmallDesktopMobile ? (
                  <p>
                    {`${startTime.format('D')}`}{' '}
                    {roomsNotAssignedCount !== null && (
                      <span className="circle">{roomsNotAssignedCount}</span>
                    )}
                  </p>
                ) : (
                  ((
                    <p>
                      {startTime.format('ddd')}
                      <br />
                      {`${startTime.format('D')}`}{' '}
                      {roomsNotAssignedCount !== null && (
                        <span className="circle">{roomsNotAssignedCount}</span>
                      )}
                    </p>
                  ) as any)
                );
              }}
              unit={
                timelineUnit === TIMELINE_UNIT.DAY ? 'hour' : (HEADER_TIME[timelineUnit] as Unit)
              }
            />
          </TimelineHeaders>
        </Timeline>
      )}

      <Tooltip
        id="timeline-tooltip"
        float
        place="top"
        anchorSelect=".rct-item"
        noArrow
        style={{
          maxWidth: '340px'
        }}
        render={({ content }) => {
          return (
            <div
              style={{
                width: '100%'
              }}
              dangerouslySetInnerHTML={{ __html: String(content) }}
            />
          );
        }}
      />
    </div>
  );
});

export default TimelineBooking;
